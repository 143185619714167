import React, { useState } from "react"
import Container from "../components/container"
import Seo from "../components/Seo"
import CustomHeader from "../components/customHeader"
import * as styles from "../components/PagesLayout.module.less"
import { DatePicker } from "antd"
import dayjs from "dayjs"
import SideBarView from "../components/SideBarView"
import localizedFormat from "dayjs/plugin/localizedFormat"
import { Typography, Button, Grid } from "@mui/material"
import { Link as GatsbyLink } from "gatsby"
import { styled } from "@mui/system"

const Link = styled(props => <GatsbyLink {...props} />)({
  color: "#69c",
  textDecoration: "none",
  "&:hover": { color: "#8fc9ff" }
})

dayjs.extend(localizedFormat)

const { RangePicker } = DatePicker

const DateDifferenceCalculator = () => {

  const getDateDiffInMilliSeconds = ({ startDate, endDate }) => {
    let diffTime = 0
    if (startDate && endDate) {
      diffTime = endDate.diff(startDate)
    }
    return 1000 * Math.floor(diffTime / 1000)
  }

  const handleDateToTimestampConversionSubmit = ({ startDate, endDate }) => {
    if (startDate && endDate) {
      updateDateRange({ startDate, endDate, showResult: true })
    }
  }

  const handleDateChange = (values, updateDateRange) => {
    const [startDate, endDate] = values
    updateDateRange({ startDate, endDate })
  }

  const [dateRange, updateDateRange] = useState({})
  const { startDate, endDate, showResult } = dateRange
  const dateRangeValues = [startDate, endDate]
  const milliSeconds = getDateDiffInMilliSeconds({ startDate, endDate })
  const seconds = milliSeconds / 1000
  let onlyDays = Math.floor(seconds / (24 * 60 * 60)) * (24 * 60 * 60)

  let onlyHours = Math.floor((seconds - onlyDays) / (60 * 60)) * (60 * 60)
  let onlyMinutes = Math.floor((seconds - onlyDays - onlyHours) / 60) * 60
  const onlySeconds = seconds - onlyDays - onlyHours - onlyMinutes
  const timeDiffStr = (onlyDays ? `${onlyDays / (24 * 60 * 60)} Days ` : ``) + (onlyHours ? `${onlyHours / (60 * 60)} Hours ` : ``) + (onlyMinutes ? `${onlyMinutes / 60} Minutes ` : ``) + (onlySeconds ? `${onlySeconds} Seconds ` : ``)
  return (
    <Container>
      <CustomHeader title={`Date Difference Calculations`}/>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={9} xl={9}>
          <p>The <b>date-time difference calculator</b> gives you the time difference in various forms like
            milliseconds, seconds, minutes, hours and days.
            The calculation of <b>milliseconds timestamp</b> will be done for both the start and end date, And then the
            change in time will be calculated from the obtained milliseconds <Link target={`blank`}
                                                                                   to={`/timestamps-list/`}>timestamps</Link>.
          </p>
          <br/>
          <h2
            style={{
              color: "#4d3267",
              fontSize: "24px",
              fontWeight: "bold",
              fontFamily: "Karla, sans-serif"
            }}
          >
            Date-time difference calculator
          </h2>
          <p>Using this tool you can find the exact time difference between any two timestamps.</p>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} xl={6}>
              <RangePicker style={{ marginBottom: "10px" }} showTime value={dateRangeValues}
                           onChange={(values) => handleDateChange(values, updateDateRange)}/>
            </Grid>
            <Grid xs={12} sm={6} md={6} xl={4}>
              {/*<p style={{ margin: 0 }}>&nbsp;</p>*/}
              <Button
                className={styles.btnLayouts}
                type="primary"
                onClick={() => handleDateToTimestampConversionSubmit({ startDate, endDate })}
              >
                Calculate the difference
              </Button>
            </Grid>
          </Grid>
          {showResult ? (
            <Grid item>
              <Grid container spacing={3}>
                <p>Difference between two
                  date <b>{dayjs(startDate).format("MMMM D, YYYY h:mm:ss A")}</b> and <b>{dayjs(endDate).format("MMMM D, YYYY h:mm:ss A")}</b> is
                  :
                  <br/>
                  <b>{timeDiffStr}</b>
                </p>
              </Grid>
              <Grid container style={{ fontSize: "16px" }} spacing={3}>
                <Grid item md={3}>
                  <p>
                    <b>Milliseconds </b>
                  </p>
                </Grid>
                <Grid item>
                  <p>:</p>
                </Grid>
                <Grid item>
                  <p style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
                    &nbsp;{milliSeconds}
                  </p>
                </Grid>
              </Grid>
              <Grid container style={{ fontSize: "16px" }} spacing={3}>
                <Grid item md={3}>
                  <p>
                    <b>Seconds </b>
                  </p>
                </Grid>
                <Grid item>
                  <p>:</p>
                </Grid>
                <Grid item>
                  <p style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
                    &nbsp;{parseInt(milliSeconds / 1000, 10)}
                  </p>
                </Grid>
              </Grid>
              <Grid container style={{ fontSize: "16px" }} spacing={3}>
                <Grid item md={3}>
                  <p>
                    <b>Minutes </b>
                  </p>
                </Grid>
                <Grid item>
                  <p>:</p>
                </Grid>
                <Grid item>
                  <p style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
                    &nbsp;{(milliSeconds / 1000) / 60}
                  </p>
                </Grid>
              </Grid>
              <Grid container style={{ fontSize: "16px" }} spacing={3}>
                <Grid item md={3}>
                  <p>
                    <b>Hours </b>
                  </p>
                </Grid>
                <Grid item>
                  <p>:</p>
                </Grid>
                <Grid item>
                  <p style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
                    &nbsp;{(milliSeconds / 1000) / 60 / 60}
                  </p>
                </Grid>
              </Grid>
              <Grid container style={{ fontSize: "16px" }} spacing={3}>
                <Grid item md={3}>
                  <p>
                    <b>Days </b>
                  </p>
                </Grid>
                <Grid item>
                  <p>:</p>
                </Grid>
                <Grid item>
                  <p style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
                    &nbsp;{(milliSeconds / 1000) / 60 / 60 / 24}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          <Grid container style={{ marginTop: "30px" }} spacing={3}>
            <br/>
            <h2
              style={{
                color: "#4d3267",
                fontSize: "24px",
                fontWeight: "bold",
                fontFamily: "Karla, sans-serif"
              }}
            >
              Date-time difference calculator usage
            </h2>
            <p>The calculator had two major field for start date time and end date time. The online date timestamp tool
              will allow you to enter year, month, day, hour, minus and seconds of the start and the end date time for
              which you to calculate difference for. the result of the conversion will be displayed in two manners one
              in single string representing days, hour, minutes and seconds. And the other way to representing time
              difference in days, hours, minutes and seconds individually.
              <br/><br/>
              The normal year with 365 days and the leap with 366 will also accounted for calculating the date-time
              difference.
              <br/><br/>
              To calculate the duration between the two time frames you just need to enter all the required values in
              the above like if you want to know how many days to Christmas Day or time elapsed from the current
              instance to new year day you should enter day, month, year in the tool.
            </p>
          </Grid>
          <Typography variant={`h5`}>More About Timestamp Difference Calculator</Typography>
          <Typography variant={`p`}>
            Timestamp difference calculators are tools that are used to calculate the difference between two timestamps.
            These calculators can be useful in a variety of situations, such as measuring the duration of an event,
            calculating the time remaining until a deadline, or determining the age of a file or record.
            <br/><br/>
            There are a few different ways to calculate the difference between two timestamps, depending on the format
            of the timestamps and the programming language or tools you are using. One common method is to use the
            built-in functions in programming languages such as Python, JavaScript, and C.
            <br/><br/>
            For example, in Python, you can use the datetime module to calculate the difference between two timestamps.
            This module provides a timedelta object that can be used to represent the difference between two datetime
            objects.
            <br/><br/>
            Another popular method is to use a library like moment.js for JavaScript. This library provides methods for
            calculating the difference between two timestamps, as well as for formatting and parsing timestamps.
            <br/><br/>
            It's also possible to use online calculators that can calculate the difference between two timestamps by
            providing the timestamps and the format, and it will provide the difference in different units (seconds,
            minutes, hours, days, etc)
            <br/><br/>
            When working with timestamps, it's important to remember that time zones can change due to daylight saving
            time. This means that some timestamps may have different representations depending on the time of year, this
            is why it's important to use libraries that take into account the current timezone rules.
            <br/><br/>
            In conclusion, timestamp difference calculators are useful tools that can be used to calculate the
            difference between two timestamps. These calculators can be implemented using built-in functions in
            programming languages, libraries, and online calculators.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={3} xl={3}>
          <SideBarView/>
        </Grid>
      </Grid>

    </Container>
  )
}
export default DateDifferenceCalculator

export const Head = () => {
  const siteMetadata = {
    description: "Calculate Date time difference in milliseconds, seconds, minutes, hours, days, weeks will be shown",
    description2: "",
    keywordsContent: "date time, time difference, milliseconds, seconds, minutes, hours, days, weeks, calculate"
  }
  return (
    <Seo
      title="Epoch Converter - Date time difference calculations"
      canonicalUrl={"https://www.epochconverter.io/date-difference-calculator/"}
      siteMetadata={siteMetadata}
    />
  )
}
